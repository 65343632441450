import { Component, Input  } from '@angular/core';
import { Frame, language } from 'curvy';
import { Task } from '@task-utils/types';
import { WorkHierarchy } from './work-hierarchies';
import { work_hierarchy_translations } from './work-hierarchy.trans';

@Component({
    selector: 'task-create-edit-work-hierarchy',
    template: `
        <h4>Work hierarchy</h4>

        <label class="input outlined" title="Name">
            <input [(ngModel)]="whier.name" />
        </label>

        <label class="input outlined" title="Description">
            <textarea [(ngModel)]="whier.name"></textarea>
        </label>

        <h4>Questionnaires</h4>

        <label class="input outlined" title="Description" *ngFor="">
            <textarea disabled [(ngModel)]="whier.name"></textarea>
        </label>
    `
})
export class CreateWorkHierarchyComponent {
    @Input()
    whier: Omit<Task.WorkHierarchy,
                'company_id' | 'whier_id' | 'category_name' |
                'category_description' | 'subcategory_name' |
                'subcategory_description' | 'group_name' |
                'group_description' | 'send_email_on_ticket_approval' |
                'automatically_approve_tickets' | 'long_description' | 'capex' |
                keyof Task.Created> = {
        parent_whier_id: null,
        name: "",
        description: "",
        level: null
    };
    @Input()
    parent_whier: Task.WorkHierarchy = null;
    questionnaires: Task.Questionnaire[] = [];
    whiers: Task.WorkHierarchy[] = [];
    current_questionnaires: Omit<Task.Questionnaire,
                                'company_id' | 'questionnaire_id' |
                                'whier_id' | keyof Task.Created>[] = [];

    constructor() {
        Frame.set({
            title: "NAVROUTE.WORKHIER",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(work_hierarchy_translations);
        WorkHierarchy.getAll().then(res => {
            this.whiers = res.data;
        });
    }

    // @TODO: Create route for searching work hierarchies.
    searchParents(name: string) {
        throw new Error("Not implemented!");
    }

    // @TODO: Can't get default questionnaire if I don't have a whier_id.
    getQuestionnaires() {
        if (this.whier["whier_id"] !== undefined) {
            WorkHierarchy.Questionnaire.get_all_by_whier_recursive(this.whier["whier_id"]).then(res =>{
                this.questionnaires = res.data.filter(q => q.whier_id !== this.whier["whier_id"]);
                this.current_questionnaires = res.data.filter(q => q.whier_id === this.whier["whier_id"]);
            });
        } else if (this.parent_whier) {
            WorkHierarchy.Questionnaire.get_all_by_whier_recursive(this.whier.parent_whier_id).then(res =>{
                this.questionnaires = res.data.filter(q => q.whier_id !== this.whier["whier_id"]);
                this.current_questionnaires = res.data.filter(q => q.whier_id === this.whier["whier_id"]);
            });
        } else {
            WorkHierarchy.Questionnaire.get_all().then(res => {
                this.questionnaires = res.data.filter(q => q.whier_id == null);
                this.current_questionnaires = res.data.filter(q => q.whier_id === this.whier["whier_id"]);
            });
        }
    }

    addQuestionnaire() {
        this.current_questionnaires.push({
            component_type_id: 3,
            question: "",
            option: null,
            group: null,
            max_images: null,
            min_images: null
        });
    }
}
