import { network } from 'curvy';
import { TaskRoutes } from '@task-utils/routes';
import { Task, DeepWriteable } from '@task-utils/types';

const { Post, Get, Patch, Delete, Headers } = network;
type Paginator = network.Headers.Paginator;

export namespace WorkHierarchy {
	export async function getAll(paginator?: Paginator) {
		return await TaskRoutes.work_hierarchies.api_work_hierarchy_get_all(paginator);
	}

	export async function search(pagination: Paginator, filter: { search: string[], attributes: string[] }) {
		let search_len = filter?.search?.length ?? 0;
		let attr_len = filter?.attributes?.length ?? 0;

		if (search_len === 0 || attr_len === 0) {
			return await getAll(pagination);
		}

		return await TaskRoutes.work_hierarchies.api_work_hierarchy_search(filter, pagination);
	}

	function construct_node(hier: Task.WorkHierarchy, all_whiers: Task.WorkHierarchy[], parent: Node<Task.WorkHierarchy>): Node<Task.WorkHierarchy> {
		let node: Node<Task.WorkHierarchy> = { data: hier, children: [], parent: parent };

		for (let h of all_whiers) {
			if (h.parent_whier_id === hier.whier_id) {
				node.children.push(construct_node(h, all_whiers, node));
			}
		}

		node.children.sort((n1, n2)=>n1.data.name.localeCompare(n2.data.name));
		return node;
	}

	export async function get_first_level(company_id?:number) {
    let filter = {"search": ["1"], "attributes": ["#=:level"]};
	 if (company_id) {
		filter.attributes.push("#=:company_id");
		filter.search.push(company_id+"");
	 }
		return await TaskRoutes.work_hierarchies.api_work_hierarchy_search(filter, {
			page_no: 1,
			page_size: 10000
		});
	}

	export async function get_child_level(pagination: Paginator, filter: { search: string[], attributes: string[] }) {
		let search_len = filter?.search?.length ?? 0;
		let attr_len = filter?.attributes?.length ?? 0;

		if (search_len === 0 || attr_len === 0) {
			return await getAll(pagination);
		}

		return await TaskRoutes.work_hierarchies.api_work_hierarchy_search(filter, pagination);
	}

	export async function get_constructed() {
		let whier_response = await WorkHierarchy.getAll({ page_no: 1, page_size: 10000 });
		let all_whiers = whier_response.data;
		let parents = all_whiers.filter(h => h.parent_whier_id === null);
		let root: Node<Task.WorkHierarchy> = {
			data: null,
			children: []
		};
		root.children = parents.map(p => construct_node(p, all_whiers, root));
		root.children.sort((n1, n2)=>n1.data.name.localeCompare(n2.data.name));

		return root;
	}

	export function get(id: number) {
		return Get<Task.Payload<Task.WorkHierarchy>>(
			TaskRoutes.work_hierarchies.work_hierarchy_get_single(id))
		.then(res => {
			return res.data;
		});
	}

	export function add(whier: Task.WorkHierarchy) {
		return TaskRoutes.work_hierarchies.api_work_hierarchy_add(whier as any);
	}

	export function modify(_whier: Task.WorkHierarchy) {
		const whier = _whier as DeepWriteable<Task.WorkHierarchy>;
		const id = whier.whier_id;
		delete whier.whier_id;

		return Patch(TaskRoutes.work_hierarchies.work_hierarchy_modify(id), whier);
	}

	export function remove(whier: Task.WorkHierarchy) {
		return Delete(TaskRoutes.work_hierarchies.work_hierarchy_delete(whier.whier_id));
	}

	export namespace Questionnaire {
		export async function get_all(paginator?: Paginator) {
			return await TaskRoutes.questionnaires.api_questionnaire_get_all(paginator);
		}

		export async function get_all_by_whier(whier_id: number) {
			return await TaskRoutes.work_hierarchies.api_work_hierarchy_questionnaures_get_all(whier_id);
		}

		export async function get_all_by_whier_recursive(whier_id: number) {
			return await TaskRoutes.work_hierarchies.api_work_hierarchy_questionnaires_get_all_recursive(whier_id);
		}
	}
}

export interface Node<T> {
	data: T;
	children: Node<T>[];
	parent?: Node<T>;
}

export function node_depth<T>(node: Node<T>, depth = 0) {
	depth++;
	if (node.children.length > 0) {
		let max_depth = depth;
		for (let child of node.children) {
			max_depth = Math.max(max_depth, node_depth(child, depth));
		}
		return max_depth;
	} else {
		return depth;
	}
}

export function find_node<T>(root: Node<T>, qualifyer: (node: Node<T>) => boolean): Node<T> {
	if (qualifyer(root)) {
		return root;
	}

	for (let child of root.children) {
		let res = find_node(child, qualifyer);
		if (res != null) {
			return res;
		}
	}

	return null;
}

export function get_chain<T>(leaf: Node<T>): Node<T>[] {
	let chain = [leaf];

	while (leaf.parent) {
		leaf = leaf.parent;
		chain.unshift(leaf);
	}
	console.log(chain);

	return chain;
}
