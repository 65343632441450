
import { language } from 'curvy';

export const english: language.TranslationDefinition = {
    locale: 'en-us',
    translations: {
        "WHIER.EDIT_BASE_QUESTIONNAIRE": "Edit base questionnaire",
        "WHIER.COMPANY": "Company",
        "CAPEX_NAME": "Name",
        "CAPEX_SAVED": "CapEx has been saved",
        "CAPEX_NOT_SAVED": "CapEx is not saved",
        "CAPEX_MODAL_TITLE": "CapEx name",
        "SWITCH_CAPEX_YEAR": "Change CapEx year",
        "CAPEX_YEAR": "CapEx year"
    }
};

export const croatian: language.TranslationDefinition = {
    locale: 'hr-hr',
    translations: {
        "WHIER.EDIT_BASE_QUESTIONNAIRE": "Uredi osnovni upitnik",
        "WHIER.COMPANY": "Tvrtka",
        "CAPEX_NAME": "Naziv",
        "CAPEX_SAVED": "CapEx spremljen",
        "CAPEX_NOT_SAVED": "CapEx nije spremljen",
        "CAPEX_MODAL_TITLE": "CapEx naziv",
        "SWITCH_CAPEX_YEAR": "Promijeni CapEx godinu",
        "CAPEX_YEAR": "CapEx godina"
    }
};

export const work_hierarchy_translations = [english, croatian];
