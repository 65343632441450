import { Component, OnInit } from '@angular/core';
import { ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { Sectors } from './sectors';

type SectorType = Omit<Task.Sector, 'company_id' | 'sector_id'>;
type Sector_Params = {
    sector: SectorType;
    company_id?: number;
};

@Component({
    selector: 'task-create-edit-sector-dialog',
    template: `
        <task-create-edit-sector [sector]="sector" [company_id]="company_id" (result)="handle_result($event)">
        </task-create-edit-sector>
    `
})
export class CreateEditSectorDialog extends ModalBase<Sector_Params, any> implements OnInit {
    preventSoftClose = true;
    sector: SectorType;
    company_id: number = null;

    is_edit: boolean = false;
    ngOnInit() {
        this.sector = this.injectedData.sector || {
            name: "",
            whiers: [],
        };
        this.company_id = this.injectedData.company_id;
    }


    handle_result(sector: SectorType) {

        if (sector == null) {
            // console.log(this.sector);
            this.submit(false);
            return;
        }

        if (this.sector !== sector) {
            this.is_edit = true;
        }

        this.sector = sector;

        let promise: Promise<any>;

        if ( this.sector["sector_id"] ) {
            promise = Sectors.modify( sector as Task.Sector );
        }

        else {
            promise = Sectors.add(sector as Task.Sector);
        }

        promise.then(( res ) => {
            this.submit(true);
        });
    }
}
