import { language } from 'curvy';

export const english: language.TranslationDefinition = {
	locale: 'en-us',
	translations: {
		"COPY": "Copy",
		"EMAIL": "Email",
		"PASS": "Password",
		"USER": "User",
		"NAME": "Name",
		"SURNAME": "Surname",
		"CREATED_BY": "Created by",
		"CREATED_BY_ME": "Me",
		"CREATED_BY_OTHERS": "Others",
		"SEARCH": "Search",
		"CONTACT": "Contact",
		"PHONE": "Phone",
		"DETAILS": "Details",
		"STARTDATE": "Start Date",
		"ENDDATE": "End Date",
		"CREATED_DATE": "Created date",
		"START_CREATED_DATE": "Created date - FROM",
		"END_CREATED_DATE": "Created date - TO",
		"START_PLANNED_DATE": "Planned date - FROM",
		"END_PLANNED_DATE": "Planned date - TO",
		"OIB": "PIN",
		"EDIT": "Edit",
		"ADD": "Add",
		"LOCATION": "Location",
		"CHANGE_LOCATION": "Change location",
		"CURRENT_LOCATION": "Current location" ,
		"NEW_LOCATION": "New location",
		"COMMENTS": "Comments",
		"COMMENT": "Comment ...",
		"STATUS": "Status",
		"GREETING_TEXT": "Welcome",
		"ERROR": "Error",
		"DELETE": "Delete",
		"SUPPLIER": "Supplier",
		"ABORT": "Abort",


		"SELECT_ALL": "Select all",
		"DESELECT_ALL": "Deselect all",

		"ERROR.INVALID_DATE_TEXT": "End date is before start date.",
		"ERROR.GENERIC_TEXT": "Something went wrong.",
		"ERROR.USER_EXISTS_TEXT": "There is active user with the same email address.",
		"ERROR.USER_GENERIC_TEXT": "Failed to add user.",
		"ERROR.FILE_UNAVAILABLE": "File can't be downloaded",


		"DOWNLOAD_TABLE": "Download table",
		"DOWNLOAD": "Download",
		"DOCUMENT.UPLOADED_TEXT": "File uploaded",
		"DOCUMENT.UPLOADED_FAIL_TEXT": "File upload failed.",
		"DOCUMENT.UNSAFE_FAIL_TEXT":"Uploaded file is not safe, therefore upload process has been aborted.",
		"DOCUMENT.UPLOADED_TOO_BIG_TEXT": (names) => `Maximum size must not exceed 10MB. Document(s) ${names} are too big.`,
		"ADMIN-BUTTONS": "Admin's actions",

		"MANUAL": "Manual",
		"MANAGE_ACCOUNT": "Manage account",

		"CATEGORY": "Category",
		"DESCRIPTION": "Description",
		"SUBCATEGORY": "Subcategory",
		"GROUP": "Group",
		"SECTOR": "Sector",
		// "CREATED": "Assigned",
		// "ASSIGNED": "Assigned",
		"ASSIGNED": "Assigned",
		"BY_ME": "By me",
		"BY_OTHERS": "By others",

		"NAVROUTE.LOGOUT": "Log out",
		"LOGOUT": "Log out",

		"NAVROUTE.MAIN": "Master data",
		"NAVROUTE.COMPANIES": "Companies",
		"NAVROUTE.DASHBOARD": "Dashboard",
		"NAVROUTE.USERS": "Users",
		"NAVROUTE.LOCATIONS": "Locations",
		"NAVROUTE.LOCATION_GROUPS": "Location groups",
		"NAVROUTE.SUPPLIERS": "Suppliers",
		"NAVROUTE.ORDERS": "Orders",
		"NAVROUTE.ORDER": (order_id) => `Order #${order_id}`,
		"NAVROUTE.TICKETS": "Tickets",
		"NAVROUTE.TICKET": (ticket_id) => `Ticket #${ticket_id}`,
		"NAVROUTE.WORKGROUPS": "Work groups",
		"NAVROUTE.SECTORS": "Sectors",
		"NAVROUTE.WORKHIER": "Work hierarchy",
		"NAVROUTE.PRICELIST": "Pricelist",
		"NAVROUTE.REPORTS": "Reports",
		"NAVROUTE.ARRIVALS": "Arrivals",
		"NAVROUTE.ARRIVAL": (arrival_id) => `Arrival #${arrival_id}`,
		"NAVROUTE.ANALYTICS": "Analytics",
		"NAVROUTE.SETTINGS": "Settings",
		"NAVROUTE.EDIT_QUESTIONNAIRES": "Edit questionnaire",
		"NAVROUTE.CALENDAR": "Calendar",
		"NAVROUTE.SCHEDULED_ORDERS": "Scheduled orders",
		"NAVROUTE.CHECKLISTS": "Check lists",

		"DELETED": "Deleted",

		"TICKET": "Ticket",
		"ORDER": "Order",
		"ARRIVAL": "Arrival",
		"SCHEDULED_ORDER": "Scheduled order",

		"STATUS.CREATED": "Created",
		"STATUS.HAS_ARRIVAL": "Arrival created",
		"STATUS.INACTIVE": "Inactive",
		"STATUS.APPROVED": "Approved",
		"STATUS.ON_HOLD": "On hold",
		"STATUS.IN_EXECUTION": "In execution",
		"STATUS.EXECUTED": "Executed",
		"STATUS.INVOICED": "Invoiced",
		"STATUS.DELETED": "Deleted",
		"STATUS.REJECTED": "Rejected",
		"STATUS.ON_COMPANY_REVIEW": "Pre-Invoicing",
		"STATUS.ON_SUPPLIER_REVIEW": "Pre-Invoicing",
		"STATUS.UNDONE": "Not done",
		"STATUS.WORKS_FINISHED": "Works finished",

		"STATUS.ON_COMPANY_REVIEW.1": "Pre-Invoicing",
		"STATUS.ON_SUPPLIER_REVIEW.1": "Pre-Invoicing (Supplier)",
		"STATUS.PING_PONG_CHANGED": "Ping-pong item changed",

		"ARRIVAL_DATE": "Arrival announced",
		"ARRIVAL_WORKERS": "Workers",

		"PRIORITY": "Priority",
		"PRIORITY.LOW": "Low priority",
		"PRIORITY.MEDIUM": "Medium priority",
		"PRIORITY.HIGH": "High priority",

		"RELATED.TICKETS": "Related tickets",
		"RELATED.ORDERS": "Related orders",
		"RELATED.ARRIVALS": "Related arrivals",

		"FINANCIAL.TITLE": "Financial details",
		"FINANCIAL_2.TITLE": "Financial elaboration",
		"FINANCIAL.INVOICE": "Invoicing",
		"FINANCIAL.INVOICE.TITLE": "Enter invoice number",
		"FINANCIAL.INVOICE.LABEL": "Invoice no.",
		"FINANCIAL.PDF_BY_LOCATION": "Approved by location",
		"FINANCIAL.PDF_ALL": "All elements",
		"FINANCIAL.CONFIRM_TITLE": (count)=>`${count} undisputed items`,
		"FINANCIAL.CONFIRM_TEXT": "Undisputed items will be confirmed and you won't be able to change them later. Are you sure you want to confirm all itmes?",
		"FINANCIAL.TAX_DISCLAIMER" : "Tax not included",
		"FINANCIAL.CHANGED_ITEM": "Changed item",
		"FINANCIAL.ITEM_DETAILS": "Item details",
		"FINANCIAL.NEW_ITEM": "New item",


		"CLICK_TO_EXPAND": "Click to expand",
		"CHECKLISTS.ADD_NEW_Q": "Add new question",

		"PDF.ORDER": "Invoice proposal",
		"PDF.ARRIVALS": "Work order",
		"PDF.ORDER.NO_PRICES": "Invoice proposal without prices",

		"KAM.ATTR": "Attributes",

		"INVENTORY.PICK": "Add inventory elements",
		"EVENT.PICK": "Choose event type",
		"USER.TAKE_OVER_LOCATIONS": "User replacement",
		"USER.RESPONSIBLE": "Responsible user",

        "SUPPLIER.IMPORT": "Import scheduled orders",
        "SUPPLIER.DOWNLOAD": "Download scheduled order",
        "SUPPLIER.DIALOG_TITLE": (supp) =>`Import pricelist dialog for ${supp}`,
        "SUPPLIER.ERRORS.DEFAULT": "Error in xlsx table processing.\n Error on table rows: ",
        "SUPPLIER.ERRORS": "Error: imported table hasn't been accepted.",
        "SUPPLIER.SUCCESS": "Pricelist has been uploaded",

		"REQUIRED_FILEDS": "Mandatory fields",
		"APPROVE" : "Approve",
		"REJECT": "Reject",

		'LOCGROUPS.NFC_ON': 'NFC scan for all locations',
        'LOCGROUPS.NFC_OFF': 'Remove NFC scan for all locations',
		'LOCGROUPS.NFC_ON_TITLE': 'Scanning arrivals with NFC on locations in this group has been ENABLED.',
		'LOCGROUPS.NFC_OFF_TITLE': 'Scanning arrivals with NFC on locations in this group has been DISABLED.',
		'LOCGROUP.LOCATION_NCF': 'NFC on location',
		'LOCGROUP.GROUP_NFC': 'NFC on group',

		'SHOW_MORE': (num)=> num > 3 ? `Show all ${num}` : 'Show all',
		'COMPANY': 'Company'

	}
};

export const croatian: language.TranslationDefinition = {
	locale: 'hr-hr',
	translations: {
		"COPY": "Kopiraj",
		"EMAIL": "Email",
		"PASS": "Lozinka",
		"USER": "Korisnik",
		"NAME": "Ime",
		"SURNAME": "Prezime",
		"CREATED_BY": "Kreirao",
		"CREATED_BY_ME": "Ja",
		"CREATED_BY_OTHERS": "Drugi",
		"SEARCH": "Traži",
		"CONTACT": "Kontakt",
		"PHONE": "Telefon",
		"DETAILS": "Detalji",
		"STARTDATE": "Početno vrijeme",
		"ENDDATE": "Vrijeme završetka",
		"CREATED_DATE": "Datum kreiranja",
		"START_CREATED_DATE": "Datum kreiranja - OD",
		"END_CREATED_DATE": "Datum kreiranja - DO",
		"START_PLANNED_DATE": "Datum planiranja - OD",
		"END_PLANNED_DATE": "Datum planiranja - DO",
		"OIB": "OIB",
		"EDIT": "Uredi",
		"ADD": "Dodaj",
		"CREATE": "Dodaj",
		"DELETE": "Obriši",
		"SUPPLIER": "Dobavljač",
		"ABORT": "Otkaži",

		"SELECT_ALL": "Označi sve",
		"DESELECT_ALL": "Odznači sve",

		"DOWNLOAD_TABLE": "Preuzmi tablicu",
		"DOWNLOAD": "Preuzmi",
		"DOCUMENT.UPLOADED_TEXT": "Datoteka uspješno uvezena.",
		"DOCUMENT.UPLOADED_FAIL_TEXT": "Datoteka se nije uspjela prebaciti.",
		"DOCUMENT.UNSAFE_FAIL_TEXT":"Datoteka nije sigurna. Prebacivanje je otkazano.",
		"DOCUMENT.UPLOADED_TOO_BIG_TEXT": (names)=> `Maksimalna dozvoljena veličina je 10MB. Datoteka ${names} je prevelika.`,
		"ADMIN-BUTTONS": "Administratorske radnje",


		"CATEGORY": "Kategorija",
		"DESCRIPTION": "Opis",
		"SUBCATEGORY": "Potkategorija",
		"GROUP": "Grupa",
		"SECTOR": "Sektor",
		"ASSIGNED": "Dodijeljen",
		"BY_ME": "Meni",
		"BY_OTHERS": "Drugima",
		"LOCATION": "Lokacija",
		"CHANGE_LOCATION": "Promijeni lokaciju",
		"CURRENT_LOCATION": "Trenutna lokacija" ,
		"NEW_LOCATION": "Nova lokacija",
		"COMMENTS": "Komentari",
		"COMMENT": "Komentar ...",
		"STATUS": "Status",
		"GREETING_TEXT": "Pozdrav",

		"ERROR": "Greška",
		"ERROR.INVALID_DATE_TEXT": "Datum završetka ne smije biti prije datuma početka dolaska.",
		"ERROR.GENERIC_TEXT": "Nešto je pošlo po zlu.",
		"ERROR.USER_EXISTS_TEXT": "E-mail adresa je već iskorištena.",
		"ERROR.USER_GENERIC_TEXT": "Korisnik nije dodan.",
		"ERROR.FILE_UNAVAILABLE": "Nije moguće preuzeti dokument",

		"MANUAL": "Upute",
		"MANAGE_ACCOUNT": "Račun",
		"NAVROUTE.LOGOUT": "Odjava",
		"LOGOUT": "Odjava",

		"NAVROUTE.MAIN": "Matični podaci",
		"NAVROUTE.COMPANIES": "Kompanije",
		"NAVROUTE.DASHBOARD": "Nadzorna ploča",
		"NAVROUTE.USERS": "Korisnici",
		"NAVROUTE.LOCATIONS": "Lokacije",
		"NAVROUTE.LOCATION_GROUPS": "Grupe lokacija",
		"NAVROUTE.SUPPLIERS": "Dobavljači",
		"NAVROUTE.ORDERS": "Nalozi",
		"NAVROUTE.ORDER": (order_id) => `Nalog #${order_id}`,
		"NAVROUTE.TICKETS": "Prijave",
		"NAVROUTE.TICKET": (ticket_id) => `Prijava #${ticket_id}`,
		"NAVROUTE.WORKGROUPS": "Grupe radova",
		"NAVROUTE.SECTORS": "Sektor",
		"NAVROUTE.WORKHIER": "Radna hijerarhija",
		"NAVROUTE.PRICELIST": "Cjenik",
		"NAVROUTE.REPORTS": "Izvještaji",
		"NAVROUTE.ARRIVALS": "Dolasci",
		"NAVROUTE.ARRIVAL": (arrival_id) => `Dolazak #${arrival_id}`,
		"NAVROUTE.ANALYTICS": "Analitika",
		"NAVROUTE.SETTINGS": "Postavke",
		"NAVROUTE.EDIT_QUESTIONNAIRES": "Uređivanje upitnika",
		"NAVROUTE.CALENDAR": "Kalendar",
		"NAVROUTE.SCHEDULED_ORDERS": "Servisi",
		"NAVROUTE.CHECKLISTS": "Kontrolne liste",

		"DELETED": "Obrisano",

		"TICKET": "Prijava",
		"ORDER": "Nalog",
		"ARRIVAL": "Dolazak",
		"SCHEDULED_ORDER": "Servis",

		"STATUS.CREATED": "Kreiran",
		"STATUS.HAS_ARRIVAL": "Dolazak kreiran",
		"STATUS.INACTIVE": "Neaktivan",
		"STATUS.APPROVED": "Odobren",
		"STATUS.ON_HOLD": "Na čekanju",
		"STATUS.IN_EXECUTION": "U izvršavanju",
		"STATUS.EXECUTED": "Izvršen",
		"STATUS.INVOICED": "Fakturirano",
		"STATUS.DELETED": "Obrisano",
		"STATUS.REJECTED": "Odbijen",
		"STATUS.ON_COMPANY_REVIEW": "Prefakturiranje",
		"STATUS.ON_SUPPLIER_REVIEW": "Prefakturiranje",
		"STATUS.UNDONE": "Neodrađeno",
		"STATUS.WORKS_FINISHED": "Radovi završeni",

		"STATUS.ON_COMPANY_REVIEW.1": "Prefakturiranje",
		"STATUS.ON_SUPPLIER_REVIEW.1": "Prefakturiranje (Dobavljač)",
		"STATUS.PING_PONG_CHANGED": "Izmjena na stavci",

		"ARRIVAL_DATE": "Dolazak najavljen",
		"ARRIVAL_WORKERS": "Radnici",

		"PRIORITY": "Prioritet",
		"PRIORITY.LOW": "Nizak prioritet",
		"PRIORITY.MEDIUM": "Srednji prioritet",
		"PRIORITY.HIGH": "Visoki prioritet",

		"RELATED.TICKETS": "Povezane prijave",
		"RELATED.ORDERS": "Povezani nalozi",
		"RELATED.ARRIVALS": "Povezani dolasci",

		"FINANCIAL.TITLE": "Financijski detalji",
		"FINANCIAL_2.TITLE": "Financijska razrada",
		"FINANCIAL.INVOICE": "Fakturiranje",
		"FINANCIAL.INVOICE.TITLE": "Unesite broj računa",
		"FINANCIAL.INVOICE.LABEL": "Račun br.",
		"FINANCIAL.PDF_BY_LOCATION": "Potvrđeno na lokaciji",
		"FINANCIAL.PDF_ALL": "Sve stavke",
		"FINANCIAL.CONFIRM_TITLE": (count)=>`Broj neosporenih stavki: ${count}`,
		"FINANCIAL.CONFIRM_TEXT": "Stavke koje niste osporili će biti potvrđene i nećete ih kasnije moći izmijeniti. Da li ste sigurni da želite potvrditi sve stavke?",
		"FINANCIAL.TAX_DISCLAIMER" : "Iznosi su bez PDV-a",
		"FINANCIAL.CHANGED_ITEM": "Osporena stavka",
		"FINANCIAL.ITEM_DETAILS": "Detalji stavke",
		"FINANCIAL.NEW_ITEM": "Nova stavka",

		"CLICK_TO_EXPAND": "Proširi prikaz",
		"CHECKLISTS.ADD_NEW_Q": "Dodaj novo pitanje",


		"PDF.ORDER": "Prilog fakturi",
		"PDF.ARRIVALS": "Radni nalog",
		"PDF.ORDER.NO_PRICES": "Prilog fakturi bez cijena",

		"KAM.ATTR": "Karakteristike",

		"INVENTORY.PICK": "Odaberi inventar",
		"EVENT.PICK": "Odaberi tip događaja",
		"USER.TAKE_OVER_LOCATIONS": "Zamjena pri odsustvu",
		"USER.RESPONSIBLE": "Odgovorna osoba",

		"SUPPLIER.IMPORT": "Uvezi servise",
        "SUPPLIER.DOWNLOAD": "Preuzmi predložak",
        "SUPPLIER.DIALOG_TITLE": (supp) =>`Uvoz servisa za ${supp}`,
        "SUPPLIER.ERRORS.DEFAULT": "Došlo je do greške kod prihvata uvezene tablice.\nGreške se nalaze u tablici na linijama: ",
        "SUPPLIER.ERRORS": "Greška: uvezena tablica nije prihvaćena.",

        "SUPPLIER.SUCCESS": "Tablica je uspješno uvezena",

		"REQUIRED_FILEDS": "Obavezno popuniti",
		"APPROVE" : "Odobri",
		"REJECT": "Odbij",

		'LOCGROUPS.NFC_ON': 'Označi NFC za sve lokacije',
        'LOCGROUPS.NFC_OFF': 'Ukloni NFC sa svih lokacija',
		'LOCGROUPS.NFC_ON_TITLE': 'NFC skeniranje dolaska za sve lokacije u grupi je UKLJUČENO.',
		'LOCGROUPS.NFC_OFF_TITLE': 'NFC skeniranje dolaska za sve lokacije u grupi je ISKLJUČENO.',
		'LOCGROUP.LOCATION_NCF': 'NFC na lokaciji',
		'LOCGROUP.GROUP_NFC': 'NFC na grupi',

		'SHOW_MORE': (num)=> num > 3 ? `Prikaži svih ${num}` : 'Prikaži više',
		'COMPANY': 'Tvrtka'


	}
};

export const nav_translations = [english, croatian];
