<div class="flex-column fullheight-noscroll">
    <h2 class="modal-title">
        {{ (is_edit_form ? 'LOCATION.EDIT' : 'LOCATION.NEW') | trans }}
    </h2>

    <div style="overflow: auto;">


        <div class="field-grid" form-collector #locationForm="collector" style="margin-bottom: 1.5em;">

            <!-- NAME -->
            <label class="input outlined" [title]="'LOCATION.LOCATION' | trans">
                <input [(ngModel)]="location.name" conditions="nonempty" />
            </label>

            <!-- CIN -->
            <label class="input outlined" [title]="'LOCATION.CIN' | trans">
                <input [(ngModel)]="location.cin" conditions="nonempty" />
            </label>

            <!-- TYPE -->
            <label class="input outlined" [title]="'LOCATION.TYPE' | trans">
                <select [(ngModel)]="location.location_type_id" required>
                    <option *ngFor="let type of location_types | keyvalue" [ngValue]="+type.key">
                        {{ ("LOCATION.TYPE." + type.value) | trans }}
                    </option>
                </select>
            </label>

            <!-- FORMAT -->
            <label class="input outlined" [title]="'LOCATION.FORMAT' | trans">
                <input type="text" [(ngModel)]="location.format">
            </label>

            <!-- DESCRIPTION -->
            <label class="input outlined whole-row" [title]="'LOCATION.DESCRIPTION' | trans">
                <input [(ngModel)]="location.description" />
            </label>

            <!-- GROSS AREA -->
            <label class="input outlined" [title]="'LOCATION.GROSS_AREA' | trans">
                <input type="number" [(ngModel)]="location.gross_area"><span>&nbsp; m<sup>2</sup></span>
            </label>

            <!-- TOTAL AREA -->
            <label class="input outlined" [title]="'LOCATION.NET_AREA' | trans">
                <input type="number" [(ngModel)]="location.net_area"><span>&nbsp; m<sup>2</sup></span>
            </label>

            <!-- EMAIL -->
            <label class="input outlined whole-row" [title]="'EMAIL' | trans">
                <i icon="email"></i>
                <input [(ngModel)]="location.email" autocomplete="off" />
            </label>

            <!-- COUNTRY -->
            <label class="input outlined" [title]="'LOCATION.COUNTRY' | trans">
                <input [(ngModel)]="location.country" />
            </label>

            <!-- AREA (ŽUPANIJA) -->
            <label class="input outlined" [title]="'LOCATION.AREA' | trans">
                <input type="text" [(ngModel)]="location.area" >
            </label>

            <!-- REGION -->
            <label class="input outlined" [title]="'LOCATION.REGION' | trans">
                <input [(ngModel)]="location.region" />
            </label>

            <!-- CITY -->
            <label class="input outlined" [title]="'LOCATION.CITY' | trans">
                <input [(ngModel)]="location.city" />
            </label>

            <!-- DISTRICT BRČKO -->
            <label class="input outlined" [title]="'LOCATION.DISTRICT' | trans">
                <input [(ngModel)]="location.district" />
            </label>

            <!-- ADDRESS -->
            <label class="input outlined whole-row" [title]="'LOCATION.ADDRESS' | trans">
                <input [(ngModel)]="location.address" />
            </label>

            <!-- POSTAL_CODE -->
            <label class="input outlined" [title]="'LOCATION.POSTAL_CODE' | trans">
                <input [(ngModel)]="location.postal_code" />
            </label>

            <!-- CONTACT PHONE -->
            <label class="input outlined" [title]="'LOCATION.CONTACT_PHONE' | trans">
                <input [(ngModel)]="location.contact_phone" />
            </label>

            <label class="checkbox-input whole-row" style="margin-left: auto;" *ngIf="'nfcs' | has_feature">
                <input type="checkbox"
                        [(ngModel)]="location.nfc" />
                &nbsp;{{"LOC.NFC" | trans }}
            </label>
        </div>

        <task-users-pool [location]="location" [is_edit_form]="is_edit_form" [users_property]="'location_users'" [required]="is_edit_form"></task-users-pool>

        <details *ngIf="location.location_groups_users?.length" class="flex-column" style="box-shadow: none; list-style: none; margin-bottom: 1.5em; margin-top: 0; margin-left: 0; padding: 0; grid-column: 1 / -1; border-top: 1px solid lightgray;">
            <summary>
                <h3 style="margin-bottom:0.5em;" >{{ 'LOCGROUP.USERS_IN_GROUP' | trans }}</h3>
            </summary>
            <div style="padding-bottom: 1em;">
                <span *ngFor="let user of location.location_groups_users" class="flex-row align-center" style="font-size: .9em; margin-bottom: .25em;">
                    <strong>{{ user.first_name }} {{ user.last_name }}</strong> &nbsp;({{ getUserType(user.user_type_id) }})
                </span>

            </div>

        </details>
    </div>

    <div class="flex-row modal-footer">
        <button color="primary"
                (click)="cancel()">
            <i icon="clear"></i>
            <span>{{ 'CANCEL' | trans }}</span>
        </button>


        <button class="filled"
                color="secondary"
                (click)="done()"
                [disabled]="locationForm.containsErrors() || save_without_users()">
            <i icon="check"></i>
            <span>{{ 'SAVE' | trans }}</span>
        </button>

    </div>
</div>
