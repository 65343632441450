import { Component, Input, OnInit  } from '@angular/core';
import { Frame, language, ModalBase } from 'curvy';
import { Task } from '@task-utils/types';
import { WorkHierarchy } from './work-hierarchies';
import { work_hierarchy_translations } from './work-hierarchy.trans';

@Component({
    selector: 'task-create-edit-work-hierarchy',
    template: `
    <div class="flex-column fullheight-noscroll">
      <h2>{{'CAPEX_MODAL_TITLE' | trans}}</h2>

      <div form-collector #capexForm="collector"  style="overflow: auto;">
         <div class="whole-row flex-column" >

               <div class="flex-row">
                  <label class="input outlined flex-dynamic" [title]="('CAPEX' | trans)" >
                     <input type="text"
                        [required]="true"
                        [(ngModel)]="capex_title"
                        placeholder="{{'CAPEX_NAME' | trans}}">
                  </label>
               </div>

         </div>
      </div>

      <div class="flex-row modal-footer">
         <button color="primary"
               (click)="submit(null)">
            <i icon="clear"></i>
            <span>{{ 'CANCEL' | trans }}</span>
         </button>

         <button class="filled"
            color="secondary"
            (click)="done()"
            [disabled]="capexForm.containsErrors() || capex_title ==''"
         >
            <i icon="check"></i>
            <span>{{ 'SAVE' | trans }}</span>
         </button>
      </div>
    </div>



    `
})
export class CapexGroupDialog extends ModalBase<Task.WorkHierarchy, string> implements OnInit {
   @Input()
   whier: Task.WorkHierarchy;

   capex_title: string;

   done() {
      this.submit(this.capex_title);
   }

   ngOnInit() {

      this.capex_title = this.injectedData.capex || "";
      language.load_translations(work_hierarchy_translations);
   }


}