import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { language, ModalBase, AutoSearchComponent } from 'curvy';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { orders_translations } from '@task-modules/orders';


interface ShortLocation {
	location_id: number;
	cin: string;
	name: string;
	address: string;
};

@Component({
	selector: 'task-change-location',
	encapsulation: ViewEncapsulation.None,
	template: `
		<div class="flex-column fullheight-noscroll" style="max-width: 580px;" form-collector #newLocationPicker="collector">
			<h2 class="flex-static modal-title">
				{{ "CHANGE_LOCATION" | trans }}
			</h2>

			<div class="flex flex-column" style="overflow: auto; gap: 1rem; max-width: 320px">

				<div class="flex" style="gap: .5rem">
					<span>{{ "CURRENT_LOCATION" | trans }} :</span>
					<strong>#{{old_location.cin}}</strong>
				</div>

				<knw-auto-search
					#locpicker
					[required]="true"
					[function]="search_locations"
					[to-label]="format_location_name"
					label="{{ 'NEW_LOCATION' | trans }}"
					[(ngModel)]="picked_location"
					[popover-width]="'auto'"
				>
				</knw-auto-search>
			</div>


			<div class="flex-row flex-static modal-footer">
				<button color="primary" (click)="cancel()">
					<i icon="clear"></i>
					{{ 'CANCEL' | trans }}
				</button>
				<button class="filled"
					color="secondary"
					(click)="save()"
					[disabled]="newLocationPicker.containsErrors()"
				>
					<i icon="check"></i>
					{{ 'SAVE' | trans }}
				</button>
			</div>
		</div>
	`,

})
export class ChangeLocationDialog extends ModalBase<ShortLocation, number> implements OnInit {
	@ViewChild('locpicker')

	preventSoftClose = true;
	allowSubmit = true;

	old_location:ShortLocation;
	picked_location:ShortLocation;

	ngOnInit() {

		this.old_location = this.injectedData;
      language.load_translations(orders_translations);
	}

	search_locations = async(search: string) => {
      let res: TaskRoutes.Payload<Task.Location[]>;

		res = await TaskRoutes.locations.api_location_search_responsible(
			{ search: [search], attributes: [`cin+ +name+`] },
			{ page_no: 1, page_size: 100 }
		);

        return res.data;
	}

	format_location_name(loc: Task.Location) {
		if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
	}

	cancel() {
		this["host"].close();
	}

	save() {
		this.submit(this.picked_location.location_id);
	}





}
