import { Routes } from '@angular/router';
import { register_global } from '@task-utils/utils';

import * as Arrivals from './arrivals';
import * as Comments from './comments';
import * as Dashboard from './dashboard';
import * as LocGroups from './location-groups';
import * as Locations from './locations';
import * as Login from './login';
import * as SetPass from './set-password';
import * as Orders from './orders';
import * as OrdersCalendar from './orders-calendar';
import * as Questionnaires from './questionnaires';
import * as Suppliers from './suppliers';
import * as Tickets from './tickets';
import * as Users from './users';
import * as WorkDescriptions from './work-groups';
import * as Sectors from './sectors';
import * as WorkHierarchy from './work-hierarchy';
import * as UserSettings from './user-settings';
import * as Pricelists from './pricelists';
import * as Reports from './reports';
import * as Checklists from './checklists';
import * as Companies from './companies';


register_global("TicketDialog", Tickets.TicketDialog);
register_global("OrderDialog", Orders.OrderDialog);
register_global("ArrivalDialog", Arrivals.ArrivalDialog);
register_global("CreateEditUserDialog", Users.CreateEditUserDialog);
register_global("UserTakeoverDialog", Users.UserTakeoverDialog);
register_global("SpoofUserDialog", Users.SpoofUserDialog);
register_global("EventInfoPopover", OrdersCalendar.EventInfoPopover);


export const components = [
	Arrivals.ArrivalComponent,
	Arrivals.ArrivalDialog,
	Arrivals.ArrivalsComponent,
	Arrivals.ConfirmArrivalComponent,
	Arrivals.ConfirmArrivalDialog,
	Arrivals.PlanArrivalComponent,
	Arrivals.PlanArrivalDialog,
	Arrivals.EditFinancialItemComponent,
	Arrivals.FinancialItemsComponent,
	Arrivals.EditArrivalDetailsComponent,
	Arrivals.FinancialItemHistoryComponent,
	Arrivals.WorkElementComponent,
	Arrivals.CompleteArrivalDialog,
	Arrivals.ArrivalSingleComponent,

	Checklists.ChecklistsComponent,
	Checklists.CreateEditQuestionComponent,
	Checklists.CreateEditQuestiontDialog,
	Checklists.ChecklistsQuestionInfo,

	Comments.CommentsComponent,
	Comments.CommentsNewComponent,

	Dashboard.DashboardComponent,
	Dashboard.DashboardInfoPopover,

	LocGroups.CreateEditLocationGroupComponent,
	LocGroups.CreateEditLocationGroupDialog,
	LocGroups.LocationGroupsComponent,

	Locations.CreateEditLocationComponent,
	Locations.CreateEditLocationDialog,
	Locations.LocationsComponent,

	Login.LoginComponent,

	Orders.CreateOrderComponent,
	Orders.CreateOrderDialog,
	Orders.OrderComponent,
	Orders.OrderDialog,
	Orders.OrdersComponent,
	Orders.AssignArrivalDialog,
	Orders.ScheduledOrderComponent,
	Orders.ScheduledOrdersComponent,
	Orders.scheduledOrderApprovedFormComponent,
	Orders.scheduledOrderFormComponent,
	Orders.EditScheduledOrderDialog,
	Orders.EditScheduledOrderApprovedDialog,
	Orders.ChangeOrderComponent,
	Orders.ChangeOrderDialog,
	Orders.ImportScheduledOrderDialog,
	Orders.CreateScheduledOrderComponent,
	Orders.NewScheduledOrderComponent,
	Orders.ScheduledOrderInfo,
	Orders.SelectedRowsInfoComponent,
	Orders.RevertItemsDialog,
	Orders.FinancialDetailsComponent,
	Orders.ChangeLocationDialog,

	OrdersCalendar.OrdersCalendarComponent,
	OrdersCalendar.EventInfoPopover,
	Orders.OrderSingleComponent,

	Questionnaires.EditQuestionnaireComponent,
	Questionnaires.EditWhierQuestionnairesComponent,
	Questionnaires.AnswerQuestionnaireComponent,

	SetPass.SetPasswordComponent,

	Suppliers.CreateEditSupplierComponent,
	Suppliers.CreateEditSupplierDialog,
	Suppliers.SuppliersComponent,

	Tickets.CreateTicketDialog,
	Tickets.TicketComponent,
	Tickets.TicketDialog,
	Tickets.TicketsComponent,
	Tickets.TicketSingleComponent,

	Users.CreateEditUserComponent,
	Users.CreateEditUserDialog,
	Users.UsersComponent,
	Users.UserTakeoverDialog,
	Users.SpoofUserDialog,

	WorkDescriptions.CreateEditWorkDescriptionComponent,
	WorkDescriptions.CreateEditWorkDescriptionDialog,
	WorkDescriptions.WorkDescriptionsComponent,

	Sectors.SectorsComponent,
	Sectors.CreateEditSectorComponent,
	Sectors.CreateEditSectorDialog,

	WorkHierarchy.CreateEditWorkHierarchyDialog,
	WorkHierarchy.CreateWorkHierarchyComponent,
	WorkHierarchy.WhierNodeComponent,
	WorkHierarchy.WorkHierarchyComponent,
	WorkHierarchy.CapexGroupDialog,

	Pricelists.PricelistsComponent,
	Pricelists.CreateEditPricelistComponent,
	Pricelists.CreateEditPricelistDialog,
	Pricelists.PricelistsComponent,
	Pricelists.ImportPricelistDialog,

	UserSettings.UserSettingsComponent,

	Reports.ReportsComponent,
	Reports.ReportFinancialComponent,
	Reports.ReportInventoryComponent,
	Reports.ReportPricelistComponent,
	Reports.ReportFreonComponent,
	Reports.ReportSupplierAdmin,

	Companies.CompaniesComponent,

	// WorkGroups.WorkDescriptionsComponent,
	// WorkGroups.CreateEditWorkDescriptionComponent,
	// WorkGroups.CreateEditWorkDescriptionDialog,
];

export const routes: Routes = [
	{ path: "arrivals", component: Arrivals.ArrivalsComponent },
	{ path: "arrivals/:id", component: Arrivals.ArrivalSingleComponent },
	{ path: "dashboard", component: Dashboard.DashboardComponent },
	{ path: "location-groups", component: LocGroups.LocationGroupsComponent },
	{ path: "locations", component: Locations.LocationsComponent },
	{ path: "login", component: Login.LoginComponent },
	{ path: "set-password/:key", component: SetPass.SetPasswordComponent },
	{ path: "set-password", component: SetPass.SetPasswordComponent },
	{ path: "orders", component: Orders.OrdersComponent },
	{ path: "scheduled-orders", component: Orders.ScheduledOrdersComponent },
	{ path: "scheduled-orders/create-scheduled-order", component: Orders.CreateScheduledOrderComponent },
	{ path: "orders-calendar", component: OrdersCalendar.OrdersCalendarComponent },
	{ path: "orders/:id", component: Orders.OrderSingleComponent },
	{ path: "suppliers", component: Suppliers.SuppliersComponent },
	{ path: "tickets", component: Tickets.TicketsComponent },
	{ path: "tickets/:id", component: Tickets.TicketSingleComponent },
	{ path: "users", component: Users.UsersComponent },
	{ path: "work-groups", component: WorkDescriptions.WorkDescriptionsComponent },
	{ path: "sectors", component: Sectors.SectorsComponent },
	{ path: "work-hierarchy", component: WorkHierarchy.WorkHierarchyComponent },
	{ path: "work-hierarchy/:id/edit-questionnaires", component: Questionnaires.EditWhierQuestionnairesComponent },
	{ path: "pricelists", component: Pricelists.PricelistsComponent },
	{ path: "pricelists/:id", component: Pricelists.PricelistsComponent },
	{ path: "user-settings", component: UserSettings.UserSettingsComponent },
	{ path: "reports", component: Reports.ReportsComponent },
	{ path: "checklists", component: Checklists.ChecklistsComponent },
	{ path: "companies", component: Companies.CompaniesComponent },
	// { path: "scheduled-orders/:id", component: Orders.ScheduledOrderComponent },
	// { path: "arrivals/:id", component: Arrivals.ArrivalComponent},
	// { path: "arrivals/:id/details", component: Arrivals.EditArrivalDetailsComponent },
	// { path: "tickets/:id", component: Tickets.TicketComponent},
	// { path: "orders/:id", component: Orders.OrderComponent},
	// { path: "orders/:id/financial-details", component: Arrivals.FinancialItemsComponent },
];
