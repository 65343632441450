import {
	Component, ViewEncapsulation, OnInit, OnChanges, ChangeDetectorRef,
	Input, Output, EventEmitter, SimpleChanges
} from '@angular/core';
import { language } from 'curvy';
import { Task } from '@task-utils/types';
import { files } from '@task-utils/utils';

import { orders_translations } from '../orders.trans';
import { OrderType } from '../orders.types';
import { TaskRoutes } from '@task-utils/routes';

@Component({
	selector: 'task-create-order',
	encapsulation: ViewEncapsulation.None,
	templateUrl: 'create-order.component.html',
	styles: [`
		task-create-order {
			max-width: 600px;
		}

		task-create-order .field-grid {
			display: grid;
			grid-auto-flow: row;
			grid-template-columns: 1fr 1fr;
			grid-gap: 0 8px;
		}

		task-create-order knw-search-picker {
			display: contents;
		}

		@media only screen and (max-width: 600px) {
			task-create-order .field-grid {
				grid-template-columns: 1fr;
			}
		}
	`]
})
export class CreateOrderComponent implements OnInit, OnChanges {

	order: OrderType;

	investment_types = Task.Investment_Type;
	edited = false

	order_description = "";
	images: files.IImage[] = [];
	financial_files: (files.IFile | Task.DMSFile)[] = [];
	service_files: (files.IFile | Task.DMSFile)[] = [];
	inventory: Task.KAM_Inventory[] = [];
	cc_emails: string[] = [];

	picked_location: Task.Location = null;
	picked_supplier: Task.Supplier = null;
	whier: Task.Ticket["whier"] = null;
	is_internal_supplier = false;
	picked_worker: Task.User = null;

	@Input()
	tickets: Task.Ticket[] = [];

	@Output('result')
	result: EventEmitter<OrderType> = new EventEmitter();


	constructor(private cdr: ChangeDetectorRef) { }

	ngOnInit() {
		language.load_translations(orders_translations);
		this.order = {
			title: "",
			supplier_id: null,
			location_id: null,
			order_details: [],
			ticket_ids: [],
			investment_type: null,
			cost_estimate: null,
			cc_emails: [],
			whier_id: null,
			iimages: [],
			ifinancial_files: [],
			iservice_files: [],
			preinvoice: false,
			lease: false,
			insurance: false,
			inspection: false,
			complaint: false,
			arrival_worker: null
		};

		if (this.tickets && this.tickets.length > 0) {
			this.order.title = this.tickets[0].title;
			this.order.complaint = this.tickets[0].complaint;

			for (let detail of this.tickets[0].ticket_details) {
				if (detail.component_type_id == Task.COMPONENT_TYPE.SERVICE_AND_SUPPORT_DOCUMENT) {
					this.service_files.push({
						ticket_detail_id: detail.ticket_detail_id,
						dms_document_file_name: detail.dms_document_file_name,
						dms_document_id: detail.dms_document_id
					});

				} else if (detail.component_type_id == Task.COMPONENT_TYPE.FINANCIAL_DOCUMENT) {
					this.financial_files.push({
						ticket_detail_id: detail.ticket_detail_id,
						dms_document_file_name: detail.dms_document_file_name,
						dms_document_id: detail.dms_document_id
					})
				}
			}
		}

		if (this.picked_supplier) {
			if (this.picked_supplier.company_type_id === Task.COMPANY_TYPE.INTERNAL_SUPPLIER) {
				this.is_internal_supplier = true;
			}
		}

	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.tickets.length > 0) {
			let text = "";
			let inventory: Task.KAM_Inventory[] = [];
			let images: Promise<files.IImage>[] = [];
			let whier: Task.Ticket["whier"] = null;

			for (let ticket of this.tickets) {
				whier = ticket.whier;

				for (let detail of ticket.ticket_details) {
					switch (detail.component_type_id) {
						case Task.COMPONENT_TYPE.TEXT:
							text += detail.answer + "\n";
							break;

						case Task.COMPONENT_TYPE.OPTION:
							text += detail.question + ': ' + detail.answer + "\n";
							break;

						case Task.COMPONENT_TYPE.TOGGLE:
							if (detail.answer) {
								text += detail.question + "\n";
							}
							break;

						case Task.COMPONENT_TYPE.IMAGE:
							images.push(files.url_to_iimage(detail.answer));
							break;

						case Task.COMPONENT_TYPE.INVENTORY:
							inventory.push({
								...detail.inventory_data,
								inventory_id: detail.inventory_id,
								inventory_name: detail.inventory_name,
								inventory_no: detail.inventory_no,
								inventory_serial_no: detail.inventory_serial_no,
							});
							break;

					}
				}
			}

			if (this.picked_location == null) {
				let location_id = this.tickets[0].location_id;
				if (location_id != null && location_id != undefined) {
					TaskRoutes.locations.api_location_get_single(location_id).then(res => {
						this.picked_location = res.data;
						this.order_description = text;
						this.inventory = inventory;
						this.whier = whier;

						return Promise.all(images);
					}).then(imgs => {
						this.images = imgs;
					});
				}
			}
		}
	}

	search_suppliers = async (search: string) => {
		let res = await TaskRoutes.suppliers.api_supplier_search(
			{ attributes: ["company.name"], search: [search] },
			{ page_no: 1, page_size: 15 }
		);

		return res.data;
	}

	format_supplier_name(supp: Task.Supplier) {
		if (!supp) { return ""; }
		return supp.name;
	}

	search_workers = async (search: string) => {
		let res = await TaskRoutes.users.api_user_search({
			search: [search, `${Task.USER_TYPE.COMPANY_WORKER}`],
			attributes: ['first_name+ +last_name', "#=:user_type_id"]
		}, {
			page_no: 1,
			page_size: 100
		})

		return res.data.sort((a,b)=>{
			return a.last_name.localeCompare(b.last_name)
		})

	}

	format_worker_name(usr: Task.User) {
		if (!usr) return "";
		return `${usr.first_name} ${usr.last_name}`
	}

	picked_supplier_handler(ev: Task.Supplier) {
		if (ev.company_type_id === 1) {
			this.is_internal_supplier = true;
		} else {
			this.is_internal_supplier = false;
		}
	}


	add_cc_email() {
		this.cc_emails.push('');
	}

	remove_cc_mail(index:number) {
		this.cc_emails.splice(index, 1)
	}

	done() {
		if (this.picked_location) {
			this.order.location_id = this.picked_location.location_id;
		}

		this.order.arrival_worker = this.picked_worker || null;


		this.order.order_details = [];
		this.order.order_details.push({
			order_detail_type_id: Task.ORDER_DETAIL_TYPE.DESCRIPTION,
			description: this.order_description
		});

		// @HACK(borna): Why is cost_estimate a string?
		this.order.cost_estimate = parseFloat(this.order.cost_estimate) as any;
		if (this.order.cost_estimate !== this.order.cost_estimate) {
			this.order.cost_estimate = null;
		}

		this.order.supplier_id = this.picked_supplier.supplier_id;
		this.order.whier_id = this.whier.whier_id;
		this.order.iimages = this.images;
		this.order.ifinancial_files = this.financial_files;
		this.order.iservice_files = this.service_files;
		this.order.cc_emails = this.cc_emails;

		for (let inv of this.inventory) {
			this.order.order_details.push({
				order_detail_type_id: Task.ORDER_DETAIL_TYPE.INVENTORY,
				description: "-", // Description is mandatory with order details
				inventory_id: inv.inventory_id,
				inventory_name: inv.inventory_name,
				inventory_no: inv.inventory_no,
				inventory_serial_no: inv.inventory_serial_no,
				inventory_data: inv
			});
		}

		this.result.emit(this.order);
	}

	cancel() {
		this.result.emit(null);
	}
}
