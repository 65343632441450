import { Component, Input, OnInit, ViewEncapsulation, ViewChild, forwardRef, ChangeDetectorRef } from '@angular/core';
import { Task } from '@task-utils/types';
import { TaskRoutes } from '@task-utils/routes';
import { language, SearchPickerComponent, async } from 'curvy';
import { components_trans } from './components.trans';
import { locations_translations } from '@task-modules/locations/locations.trans';
import { users_translations } from '@task-modules/users/users.trans';
import { User } from '@task-modules/users';



@Component({
	selector: 'task-users-pool',
	encapsulation: ViewEncapsulation.None,
	template: `
	<details open class="flex-column" style="box-shadow: none; list-style: none; margin-top: 0; margin-bottom: 0; margin-left: 0; padding: 0; grid-column: 1 / -1; border-top: 1px solid lightgray;">
		<summary>
			<h3 class="pool-title" style="margin-bottom:0.5em;">
				<span [ngStyle]="{color: (location[this.users_property].length === 0 && required) ? '#FF6161':'inherit'}">
					{{ 'NAVROUTE.USERS' | trans }}
					<ng-container *ngIf="location[this.users_property].length === 0 && required">*</ng-container>
				</span>
				<!-- <span *ngIf="location[this.users_property].length > 0">{{ 'NAVROUTE.USERS' | trans }}</span>
				<span *ngIf="location[this.users_property].length === 0 && required" style="color: #FF6161;">{{ 'NAVROUTE.USERS' | trans }} *</span> -->
				<div class="score" *ngIf="!is_pool_open">
					<i icon="person_pin_circle" title="{{ 'USER.TYPE.STORE_MANAGER' | trans }}"></i>{{ countUserType(7) }}
					<i icon="person" title="{{ 'USER.TYPE.REGIONAL_RETAIL_MANAGER' | trans }}"></i>{{ countUserType(6) }}
					<i icon="manage_accounts" title="{{ 'USER.TYPE.REGIONAL_MAINTENANCE_MANAGER' | trans }}"></i>{{ countUserType(5) }}
					<i icon="engineering" title="{{ 'USER.TYPE.COMPANY_WORKER' | trans }}"></i>{{ countUserType(12) }}
					<i icon="person_pin_circle" title="{{ 'USER.TYPE.AUTHORIZED_PERSONEL' | trans }}"></i>{{ countUserType(4) }}
					<i icon="manage_accounts" title="{{ 'USER.TYPE.SAFETY_MANAGER' | trans }}"></i>{{ countUserType(13) }}

				</div>
			</h3>
		</summary>
		<div style="padding-bottom: 1em;">
			<div class="field-grid">

				<div class="flex-row whole-row">
					<!-- USER PICKER -->
					<knw-auto-search #userpicker
						[function]="search_users"
						[label]="'NAVROUTE.USERS' | trans"
						[to-label]="format_user_name"
						[(ngModel)]="selected_user"
						[popover-width]="'auto'"
						class="outlined flex-dynamic">
					</knw-auto-search>
					<button (click)="addUser()" class="flex-static pill">
						<i icon="add_circle"></i>
						<span>{{ 'ADD' | trans}}</span>
					</button>
				</div>

				<div class="semafor" [ngClass]="{ 'fade-in': selected_user }">
					<span *ngIf="selected_user">
						{{ 'USER.TYPE.'+allUserTypes[selected_user.user_type_id] | trans }}
					</span>
				</div>

			</div>
			<div class="flex-row">
				<!-- USERS POOL -->
				<div class="pool-container">
					<h4><i icon="person_pin_circle"></i>{{ 'USER.TYPE.STORE_MANAGER' | trans }}</h4>
					<div *ngFor="let user of location[users_property]; let i = index">
						<div *ngIf="user.user_type_id == 7" class="pool-item item-opened">
							<button color="warn" class="round" (click)="removeUserFromPool(i)"><i icon="clear"></i></button>
							<div class="p-title">{{ user.first_name }} {{ user.last_name }}</div>
						</div>
					</div>
					<h4><i icon="account_box"></i>{{ 'USER.TYPE.REGIONAL_RETAIL_MANAGER' | trans }}</h4>
					<div *ngFor="let user of location[users_property]; let i = index">
						<div *ngIf="user.user_type_id == 6" class="pool-item item-opened">
							<button color="warn" class="round" (click)="removeUserFromPool(i)"><i icon="clear"></i></button>
							<div class="p-title">{{ user.first_name }} {{ user.last_name }}</div>
						</div>
					</div>
					<h4><i icon="manage_accounts"></i>{{ 'USER.TYPE.REGIONAL_MAINTENANCE_MANAGER' | trans }}</h4>
					<div *ngFor="let user of location[users_property]; let i = index">
						<div *ngIf="user.user_type_id == 5" class="pool-item item-opened">
							<button color="warn" class="round" (click)="removeUserFromPool(i)"><i icon="clear"></i></button>
							<div class="p-title">{{ user.first_name }} {{ user.last_name }}</div>
						</div>
					</div>
					<h4><i icon="manage_accounts"></i>{{ 'USER.TYPE.COMPANY_WORKER' | trans }}</h4>
					<div *ngFor="let user of location[users_property]; let i = index">
						<div *ngIf="user.user_type_id == 12" class="pool-item item-opened">
							<button color="warn" class="round" (click)="removeUserFromPool(i)"><i icon="clear"></i></button>
							<div class="p-title">{{ user.first_name }} {{ user.last_name }}</div>
						</div>
					</div>
					<h4><i icon="person_pin_circle"></i>{{ 'USER.TYPE.AUTHORIZED_PERSONEL' | trans }}</h4>
					<div *ngFor="let user of location[users_property]; let i = index">
						<div *ngIf="user.user_type_id == 4" class="pool-item item-opened">
							<button color="warn" class="round" (click)="removeUserFromPool(i)"><i icon="clear"></i></button>
							<div class="p-title">{{ user.first_name }} {{ user.last_name }}</div>
						</div>
					</div>
					<h4><i icon="manage_accounts"></i>{{ 'USER.TYPE.SAFETY_MANAGER' | trans }}</h4>
					<div *ngFor="let user of location[users_property]; let i = index">
						<div *ngIf="user.user_type_id == 13" class="pool-item item-opened">
							<button color="warn" class="round" (click)="removeUserFromPool(i)"><i icon="clear"></i></button>
							<div class="p-title">{{ user.first_name }} {{ user.last_name }}</div>
						</div>
					</div>

					<h4><i icon="manage_accounts"></i>{{ 'USER.TYPE.MANAGER_1' | trans }}</h4>
					<div *ngFor="let user of location[users_property]; let i = index">
						<div *ngIf="user.user_type_id == 14" class="pool-item item-opened">
							<button color="warn" class="round" (click)="removeUserFromPool(i)"><i icon="clear"></i></button>
							<div class="p-title">{{ user.first_name }} {{ user.last_name }}</div>
						</div>
					</div>


					<h4><i icon="manage_accounts"></i>{{ 'USER.TYPE.MANAGER_2' | trans }}</h4>
					<div *ngFor="let user of location[users_property]; let i = index">
						<div *ngIf="user.user_type_id == 15" class="pool-item item-opened">
							<button color="warn" class="round" (click)="removeUserFromPool(i)"><i icon="clear"></i></button>
							<div class="p-title">{{ user.first_name }} {{ user.last_name }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</details>
	`,
	styleUrls: ['./components.scss'],
})
export class UsersPool implements OnInit {

	@ViewChild('userpicker')
    userpicker: SearchPickerComponent;

	@Input()
    location: Partial<Task.Location> | Partial<Task.LocationGroup>;

	@Input()
	is_edit_form = false;

	@Input()
	users_property: 'location_users' | 'location_group_users' = 'location_users';

	@Input()
	required = true;

	allowedUsers: number[] = [
        Task.USER_TYPE['REGIONAL_MAINTENANCE_MANAGER'],
        Task.USER_TYPE['REGIONAL_RETAIL_MANAGER'],
        Task.USER_TYPE['STORE_MANAGER'],
        Task.USER_TYPE['COMPANY_WORKER'],
        Task.USER_TYPE['AUTHORIZED_PERSONEL'],
        Task.USER_TYPE['SAFETY_MANAGER'],
		  Task.USER_TYPE['MANAGER_1'],
		  Task.USER_TYPE['MANAGER_2']


    ];

	selected_user: Task.User = null;
    allUserTypes = Task.User_Type;
	is_pool_open: boolean = false;
    is_first_open: boolean = false;
    style_item: string = "whiers-item item-open";

	private on_changed;
	private on_touched;

	constructor(public cdr: ChangeDetectorRef) {}

	ngOnInit() {
		language.load_translations(locations_translations);
		language.load_translations(users_translations);

	}

	async search_users(search: string) {
        let res = await TaskRoutes.users.api_user_search({ search: [search], attributes: ["first_name+ +last_name"] });

		return res.data;
    }

	format_user_name(usr: Task.User) {
		if (!usr) { return ""; }
		return `${usr.first_name} ${usr.last_name} (${usr.email})` ;
	}

	removeUserFromPool(index: number) {
        this.location[this.users_property].splice(index,1);
    }

	has_company_workers(): boolean {
		if(this.is_edit_form) {
			if (this.location[this.users_property].length>0) {
			return this.location[this.users_property].some(u=>u.user_type_id==Task.USER_TYPE.COMPANY_WORKER)
			} else return false;
		} else return false;
	}

	addUser() {
        if (
            this.selected_user &&
            this.allowedUsers.includes(this.selected_user.user_type_id) &&
            !this.isExist(this.selected_user.user_id)
        ) {
            this.location[this.users_property].push({
                first_name: this.selected_user.first_name,
                last_name: this.selected_user.last_name,
                user_id: this.selected_user.user_id,
                user_type_id: this.selected_user.user_type_id
            } as Task.User);
        }
        this.selected_user = null;
    }

	countUserType( userType: number ): string {
        let outNumber: number = 0;
        if( this.location[this.users_property] && this.location[this.users_property].length > 0 ) {
            this.location[this.users_property].forEach((item: Task.User ) => {
                if( item.user_type_id == userType ) {
                    outNumber++;
                }
            });
        }

        return outNumber + '';
    }

	isExist( userId: number ): boolean {
        let out: boolean = false;
        if ( this.location[this.users_property].length > 0 ) {
            this.location[this.users_property].forEach(
                (item) => {
                    if( item.user_id == userId ) {
                        out = true;
                    }
                }
            );
        }
        return out;
    }



	registerOnChange(fn) {
		this.on_changed = fn;
	}

	registerOnTouched(fn) {
		this.on_touched = fn;
	}

}
