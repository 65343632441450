import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Frame, language, TableConfig, network, GenericFilter, noOp, template, modal, GenericFilterComponent, DataTableComponent, date, utils, ConfirmDialogComponent, color, InformDialog } from 'curvy';
import { Task } from '@task-utils/types';
import { CreateOrderDialog } from '@task-modules/orders/create-order/create-order.dialog';
import { User } from '@task-modules/users';
import { Order } from './orders';
import { orders_translations } from './orders.trans';
import { components_trans } from '@task-components/components.trans';
import { TextBoxDialog } from '@task-components/textbox-dialog.component';
import { TaskRoutes } from '@task-utils/routes';

type TableType = DataTableComponent<Task.Order, DisplayOrder, 'orders', GenericFilterComponent>;

function is_responsible(user: Task.User, order: Task.Order) {
    return order.authorized_maintenance_managers.some(u=>u.user_id === user.user_id)
};

@Component({
    selector: 'task-orders',
    template: `
        <div class="temp-add-button">
            <button (click)="openAdd()"
                    class="filled pill"
                    color="primary"
                    *ngIf="can_create_order">
                <i icon="add_circle"></i>
                <span>{{ 'ORDER.NEW' | trans }}</span>
            </button>
        </div>
        <knw-data-table #orderstable [config]="tableConfig">
        </knw-data-table>`,
    encapsulation: ViewEncapsulation.None,
    styles: [`
        task-orders {
            display: flex;
            flex-flow: column nowrap;
            overflow: hidden !important;
        }

        html task-orders .table-wrapper .table-column-details.table-body-cell,
        html task-orders .table-wrapper .table-column-status.table-body-cell,
        html task-orders .table-wrapper .table-column-supplier.table-body-cell,
        html task-orders .table-wrapper .table-column-title.table-body-cell {
            display: flex;
            flex-flow: column nowrap;
        }

        html task-orders .table-wrapper .table-column-title.table-body-cell {
            align-items: stretch;
            text-align: left;
        }
    `]
})
export class OrdersComponent {
    @ViewChild('orderstable') table: TableType;

    currUser: Task.User = null;
    statuses = [
        { label: "STATUS.CREATED", value: Task.STATUS.CREATED },
        { label: "STATUS.IN_EXECUTION", value: Task.STATUS.IN_EXECUTION },
        { label: "STATUS.ON_SUPPLIER_REVIEW.1", value: Task.STATUS.ON_SUPPLIER_REVIEW },
        { label: "STATUS.ON_COMPANY_REVIEW.1", value: Task.STATUS.ON_COMPANY_REVIEW },
        { label: "STATUS.EXECUTED", value: Task.STATUS.EXECUTED },
        { label: "STATUS.INVOICED", value: Task.STATUS.INVOICED },
        { label: "STATUS.REJECTED", value: Task.STATUS.REJECTED },
        { label: "STATUS.DELETED", value: Task.STATUS.DELETED }
    ];

    constructor() {
        Frame.set({
            title: "NAVROUTE.ORDERS",
            visible: true,
            layout: "middle",
            size: "full"
        });
        language.load_translations(orders_translations);
        language.load_translations(components_trans);

        this.resolve_rights();


    }

    can_create_order = false;

    async resolve_rights() {
        this.can_create_order = await User.currentTypeIs(
            Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
            Task.USER_TYPE.SAFETY_MANAGER,
            Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN);
    }

    async search_locations(search: string) {
        let res = await TaskRoutes.locations.api_location_search_visible(
            { search: [search], attributes: ["cin+ +name"] },
            { page_no: 1, page_size: 50 }
        );
        return res.data;
    }

    format_location_name(loc: Task.Location) {
        if (!loc) { return ""; }
		return `${loc.cin ?? ''} ${loc.name}`;
    }

    async search_location_groups(search: string) {
        let res = await TaskRoutes.location_groups.api_location_groups_search_visible({
            search: [search],
            attributes: ["name"]
        }, {
            page_no: 1,
            page_size: 10
        }, false)

        return res.data;
    }

    format_location_group_name(loc: Task.LocationGroup) {
        if (!loc) return "";
        return `${loc.name}`
    }

    async search_suppliers(search: string) {
        let res = await TaskRoutes.suppliers.api_supplier_search({
            search: [search],
            attributes: ["company.name"]
        }, {page_no: 1, page_size: 15});

        return res.data;
    }

    format_supplier_name(supp: Task.Supplier|string) {
        if (!supp) return;
        if (typeof(supp) =="string") return supp;

        return `${supp.name}`
    }

    search_statuses = async (search: string) => {
        return this.statuses;
    }

    format_status_name(status) {
        if (!status) return;
        return language.translate(status.label);
    }

    search_company_workers = async(search: string) => {
        let res = await TaskRoutes.users.api_user_search({
            search: [search, `${Task.USER_TYPE.COMPANY_WORKER}`],
            attributes: ['first_name+ +last_name', "#=:user_type_id"]
            }, {
                page_no: 1,
                page_size: 10
            })
        return res.data
    }

    format_company_workers = (user: Task.User) => {
        if(!user) {
            return ""
        }
        return `${user.first_name} ${user.last_name}`
    }

    async search_companies(search: string) {
        let res = await TaskRoutes.companies.api_company_search(
            { search: [Task.COMPANY_TYPE.MAIN], attributes: ["#=:company_type_id"] },
            { page_no: 1, page_size: 10 }
        )

        return res.data;
    }

    format_company_name(comp: Task.Company) {
        if (!comp) return "";
        return comp.name;
    }

    get_filters = async () => {
        let filters: GenericFilterComponent["filterInput"] = [
            GenericFilter.text("ORDER.TITLE", "order"),
            GenericFilter.search("STATUS", "status_id", this.search_statuses, this.format_status_name, undefined, undefined, undefined, true, true),
            GenericFilter.toggle("STATUS.HAS_ARRIVAL", "active_arrivals"),
            GenericFilter.date_filter("START_CREATED_DATE", "created_date_from"),
            GenericFilter.date_filter("END_CREATED_DATE", "created_date_to"),
            GenericFilter.search("COMP.WHIER", "whier", this.search_whier, this.format_whier_name),
            GenericFilter.toggle("ORDER.RECLAMATION", "b=:complaint"),
            GenericFilter.text("ORDER.INVOICE_NO", "=:invoice_no")
        ];

        if (!await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN)) {
            filters.push(GenericFilter.search("SUPPLIER", "supplier", this.search_suppliers, this.format_supplier_name),
            GenericFilter.search('USER.TYPE.COMPANY_WORKER', 'arrival_worker_id', this.search_company_workers, this.format_company_workers));
        }

        if (await User.currentTypeIs(
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER,
                Task.USER_TYPE.SAFETY_MANAGER,
                Task.USER_TYPE.ADMIN,
                Task.USER_TYPE.SYSTEM_ADMIN) && !(await User.currentTypeIs(Task.USER_TYPE.SAFETY_MANAGER))
        ) {
            let default_value = "me";


            if (await User.currentTypeIs(Task.USER_TYPE.ADMIN, Task.USER_TYPE.SYSTEM_ADMIN)) {
                default_value = null;
            }

            filters.push(
                GenericFilter.search("ASSIGNED", "assigned", this.search_users, this.format_users, true, default_value)
            )

            filters.push(
                GenericFilter.dropdown("CREATED_BY", "created", [
                    { label: "CREATED_BY_ME", value: "me" },
                    { label: "CREATED_BY_OTHERS", value: "others" }
                ], default_value)
            );
        }

        filters.splice(1,0,GenericFilter.search("NAVROUTE.LOCATION_GROUPS", "loc_groups", this.search_location_groups, this.format_location_group_name));

        if (await User.currentTypeIs(Task.USER_TYPE.SUPPLIER_ADMIN)) {
            filters.splice(1, 0, GenericFilter.text("LOCATION", "location.cin+ +location.name"))
        } else {
            filters.splice(1, 0,
                GenericFilter.search("LOCATION", "location", this.search_locations, this.format_location_name, true)
            )
        }


        if (await User.is_feature_flag_enabled('scheduled_orders')) {
            filters.push(GenericFilter.toggle("ORDER.IS_SHC_ORDER", "scheduled_order_id"))
        }

        if (await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN)) {
            filters.push(GenericFilter.search("COMPANY", "company", this.search_companies, this.format_company_name));

        }


        return filters;
    }

    openAdd() {
        modal.open(CreateOrderDialog, () => {
            this.table.getData();
        });
    }

    async search_whier(search: string) {

        return (await TaskRoutes.work_hierarchies.api_work_hierarchy_search({
            search: [search, Task.WHIER_LEVEL.CATEGORY+""],
            attributes: ["name+ +description", '#=:level']
        }, {
            page_no: 1,
            page_size: 25
        })).data;


    }

    format_whier_name(whier: Task.WorkHierarchy) {
        if (!whier) return;

        return `${whier.description}`
    }

    async search_users(search: string) {
        let wanted_types = [
                Task.USER_TYPE.SAFETY_MANAGER + "",
                Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER + ""
        ];
        let unwanted_types = Object.keys(Task.User_Type).filter(u => !wanted_types.includes(u));

        let res = await TaskRoutes.users.api_user_search({
            search: [...unwanted_types, search],
            attributes: [...unwanted_types.map(() => "!#:user_type_id"), "first_name+ +last_name"]
        }, {
            page_no: 1,
            page_size: 25
        })

        return ["me", "others", ...res.data]

    }

    format_users(user: Task.User | "others" | "me") {
        if (!user) return;

        let name = "";

        if (typeof(user) == "string") {
            name = user === "me" ? language.translate("BY_ME") : language.translate("BY_OTHERS");
        } else {
            name = `${user.first_name} ${user.last_name}`;
        }

        return name;
    }

    tableConfig: TableType["tableConfig"] = {
        tableName: 'orders',
        getData: (filter, paginator, sort_header) => {
            return User.getCurrent().then((res: Task.User) => {
                let parsed_filter = { search: [], attributes: [], sort: null};
                this.currUser = res;

                for (let key in filter) {
                    if (key == "order") {
                        let re_num = /^[0-9]+$/;
                        let is_id = filter[key].match(re_num);
                        let attr = is_id ? '#=:order_id' : 'order_id+ +title';
                        parsed_filter.attributes.push(attr);
                        parsed_filter.search.push(filter[key]);

                    } else if(key === 'arrival_worker_id' && filter['arrival_worker_id'] !== null){
                        parsed_filter.attributes.push("#=:arrival_worker_id")
                        parsed_filter.search.push(filter.arrival_worker_id.user_id)

                    } else if (key === 'company' && filter['company'] !== null) {
                        parsed_filter.attributes.push('#=:company_id');
                        parsed_filter.search.push(filter[key].company_id);

                    } else  if (key == 'created_date_from') {
                        let start = date.start_of_day(filter[key]);
                        parsed_filter.attributes.push("-d:created_date");
                        parsed_filter.search.push(start.toISOString());

                    } else if (key == 'created_date_to') {
                        let end = date.end_of_day(filter[key]);
                        parsed_filter.attributes.push("+d:created_date");
                        parsed_filter.search.push(end.toISOString());

                    } else if ( key== "loc_groups") {
                        parsed_filter.attributes.push("#=:loc_groups.location_group_id");
                        parsed_filter.search.push(filter.loc_groups.location_group_id);

                    } else if (key == "location" && filter["location"] !== null) {
                        parsed_filter.attributes.push("#=:location_id");
                        parsed_filter.search.push(filter.location.location_id);

                    } else if (key == "whier") {
                        parsed_filter.attributes.push("#=:category.whier_id");
                        parsed_filter.search.push(filter.whier.whier_id);

                    } else if (key == "created" && filter["created"] !== null) {
                        if (
                            this.currUser.user_type_id=== Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER ||
                            this.currUser.user_type_id === Task.USER_TYPE.SAFETY_MANAGER
                        ) {
                            if(filter["created"] == "me") {
                                parsed_filter.attributes.push("assigned_to");
                                parsed_filter.search.push("me");

                            } else {
                                parsed_filter.attributes.push("assigned_to");
                                parsed_filter.search.push("others");
                            }
                        } else {
                            if (filter["created"] === "me") {
                                parsed_filter.attributes.push("#=:created_by_id");
                                parsed_filter.search.push(this.currUser.user_id);

                            } else {
                                parsed_filter.attributes.push("#!:created_by_id");
                                parsed_filter.search.push(this.currUser.user_id);
                            }

                        }
                    } else if (key == "status_id") {
                        if (!filter[key] || !filter[key].length) continue;
                        let target_statuses = filter[key] as typeof this.statuses;
                        let exclude_statuses = Object.keys(Task.Status).filter(s => !target_statuses.some(t => t.value == +s));

                        for (let status of exclude_statuses) {
                            parsed_filter.attributes.push("#!:status_id");
                            parsed_filter.search.push(status);
                        }

                    } else if (key == "active_arrivals") {
                        if (filter[key]) {
                            parsed_filter.attributes.push("#!:active_arrivals.count");
                            parsed_filter.search.push(0);
                        } else {
                            parsed_filter.attributes.push("#=:active_arrivals.count");
                            parsed_filter.search.push(0);
                        }

                    } else if (key == "supplier" && filter["supplier"]!== null) {
                        parsed_filter.attributes.push("#=:supplier_id");
                        parsed_filter.search.push(filter.supplier.supplier_id);

                    } else if ( key == "scheduled_order_id" && filter["scheduled_order_id"] !== null) {
                        if (filter["scheduled_order_id"] !== null) {
                            if (filter[key]) {
                                parsed_filter.attributes.push("!?:scheduled_order_id");
                                parsed_filter.search.push("");

                            } else {
                                parsed_filter.attributes.push("=?:scheduled_order_id");
                                parsed_filter.search.push("");
                            }
                        }
                    } else if (key == "assigned" && filter["assigned"] !== null) {
                        parsed_filter.attributes.push("=:assigned_to");
                        parsed_filter.search.push(filter[key].user_id ?? filter[key]);

                    } else {
                        parsed_filter.attributes.push(key);
                        parsed_filter.search.push(filter[key]);
                    }
                }

                if (sort_header) {
                    switch (sort_header.label) {
                        case 'title':
                            sort_header.label = '#:order_id';
                            break;

                        case 'supplier':
                            sort_header.label = 'suppliers_company.name';
                            break;

                        case 'arrivals':
                            sort_header.label = '#:total_arrivals.count';
                            break;
                    }
                }

                parsed_filter.sort = sort_header;

                return TaskRoutes.orders.api_order_search(parsed_filter, paginator, true);
                // return Order.search(paginator, parsed_filter);
            });
        },
        getHeader: (header) => {
            switch (header) {
                case 'status':
                    return { icon: "offline_bolt", label: "", sortable: false };

                    case 'title':
                    return { icon: "ballot", label: "ORDER.TITLE", sortable: true };

                case 'details':
                    return { icon: "details", label: "DETAILS", sortable: false }; // numbers in strings don't sort correctly so we are skipping it for now

                case 'supplier':
                    return { icon: "local_shipping", label: "SUPPLIER", sortable: true };

                case 'arrivals':
                    return { icon: '', label: "ORDER.ARRIVALS_COUNT", sortable: true } // check this
            }
        },
        unwrapData: (order) => {
            let status_created = order.status_id == Task.STATUS.CREATED;
            let has_arrivals = order.active_arrivals > 0 && status_created;
            let ping_pong_changed = language.translate('STATUS.PING_PONG_CHANGED');
            let warning = (order.status_id == Task.STATUS.ON_SUPPLIER_REVIEW) && order.ping_pong_count > 0
            ? `
                <i icon="notifications_active" class="tooltip-icon" style="padding: 0 8px 0 0; color: rgba(var(--warn));" tooltip="${ping_pong_changed}"></i>
            ` : ``;

            let creator = order.authorized_maintenance_managers.length ? order.authorized_maintenance_managers.map((auth) => `${auth.first_name} ${auth.last_name}`).join(', ') : '';
            return {
                status: template.transform(`
                    <div class="flex-row align-center justify-center"><i style="color: {{icon_color}}">{{icon_name|icon}}</i>
                    ${warning}</div>
                    <div style="font-size: 0.8em">
                        {{ status_name | trans }}
                    </div>
                    <div style="font-size: 0.8em">
                        {{ relative | date << R }}
                    </div>
                `, {
                    icon_name: has_arrivals ? Task.HAS_ARRIVAL_ICON.icon : Task.StatusIcons[order.status_id].icon,
                    icon_color: has_arrivals ? Task.HAS_ARRIVAL_ICON.color : Task.StatusIcons[order.status_id].color,
                    status_name: has_arrivals ? language.translate("ARRIVAL_DATE") : language.translate("STATUS."+Task.Status[order.status_id]),
                    relative: has_arrivals ? order.next_arrival_date : order.status_date || order.created_date
                }),
                title: template.transform(`
                    <span style="font-size: 0.8em">
                        {{ created_date | date }}
                    </span>
                    <span style="color: rgb(var(--primary))">
                        <b>#{{id}}</b> <span class="truncate-text">{{ title }}</span>
                    </span>
                `, {
                    id: order.order_id,
                    title: order.title,
                    created_date: order.created_date
                }),
                supplier: template.transform(`
                    <b>{{ name }}</b>
                `, {
                    name: order.supplier_name || ''
                }),
                arrivals: template.transform(`
                    <span>{{ total_arrivals }}</span>
                `, {
                    total_arrivals: order.total_arrivals|| "",
                }),
                details: template.transform(`
                    <b>{{ location }}</b>
                    <span>{{ address }}</span>
                    <span>{{ created_by }}</span>
                `, {
                    location: order.location_name,
                    address: order.location_address,
                    created_by: creator
                })
            }
        },
        showHeaderWhenEmpty: true,
        maxActions: window.innerWidth < 600 ? 1 : 3,
        rowActions: [{
            label: language.translate('EDIT'),
            icon: "edit",
            onClick: (order) => {
                utils.router.navigateByUrl("/orders/" + order.order_id);
            },
            priority: 1,
            isVisible: ()=>true
        },
        {
            label: language.translate('DELETE'),
            icon: "delete",
            color: "warn",
            onClick: (order) => {
                Order.delete_order_dialog(order, () => this.table.getData());
            },
            priority: 2,
            isVisible: async (order)=> {
                let is_admin = await User.currentTypeIs(Task.USER_TYPE.SYSTEM_ADMIN, Task.USER_TYPE.ADMIN);
                let is_creator = await User.currentTypeIs(Task.USER_TYPE.SAFETY_MANAGER,Task.USER_TYPE.REGIONAL_MAINTENANCE_MANAGER) && is_responsible(this.currUser, order);

                let user_can_delete = is_admin || is_creator;

                if (!user_can_delete) return false;

                let allowCreated = order.status_id === Task.STATUS.CREATED;
                let allowInexecution = order.status_id === Task.STATUS.IN_EXECUTION && order.active_arrivals == 0;

                return allowCreated || allowInexecution;
            }
        }],
        filterComponent: GenericFilterComponent,
        filterInput: this.get_filters
    }
}

interface DisplayOrder {
    status: template.EscapedHTML;
    title: template.EscapedHTML;
    supplier: template.EscapedHTML;
    details: template.EscapedHTML;
    arrivals: template.EscapedHTML;
}
